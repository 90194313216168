<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            {{ createDialog ? 'New Entry' : 'Edit Entry' }}
            <v-spacer></v-spacer>
        </v-card-title>
        <v-divider class="mx-5" />
        <v-card-text>
            <v-form v-model="valid" class="mx-5 mt-3">
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!createDialog"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="entry.date"
                                    label="Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.required]"
                                    required
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="entry.date"
                                @input="menu = false"
                                :min="min"
                                type="month"
                                scrollable
                                :allowed-dates="allowedDates"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col cols="12">
                        <v-text-field
                            v-model="entry.percentage"
                            type="number"
                            label="Percentage"
                            prepend-icon="mdi-percent"
                            @input="calculateAmount()"
                            :rules="[rules.number]"
                            hide-details
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n1"
                    ><v-col cols="12">
                        <v-text-field
                            v-model="entry.amount"
                            type="number"
                            label="Amount"
                            required
                            prepend-icon="mdi-currency-usd"
                            @input="calculatePercentage()"
                            :rules="[rules.required, maxAmount()]"
                        >
                        </v-text-field> </v-col
                ></v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-checkbox
                v-model="checkbox"
                :label="'Set as an initial forecast'"
                v-if="
                    !createDialog && isFirstFiveDaysOfMonth() && isCurrentMonth
                "
            ></v-checkbox>
            <v-checkbox
                v-model="checkbox"
                :label="'Set as an initial forecast'"
                v-else-if="
                    createDialog && isFirstFiveDaysOfMonth() && isCurrentMonth
                "
            ></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="addEntry"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
    name: 'ForecastEntryForm',
    props: {
        createDialog: {
            type: Boolean,
            required: true,
            default: () => true,
        },
        originalEntry: {
            type: Object,
            required: true,
            default: () => {},
        },
        dates: {
            type: Array,
            required: true,
            default: () => [],
        },
        forecast: {
            type: Array,
            required: true,
            default: () => [],
        },
        quote: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                number: v => v >= 0 || 'Not valid',
            },
            entry: {},
            min: new Date().toISOString(),
            menu: false,
            checkbox: true,
            isCurrentMonth: false,
        }
    },
    async mounted() {
        try {
            this.loading = true
            this.entry = _.cloneDeep(this.originalEntry)

            const forecastedAmount = this.calculateForecastedAmount()
            this.maxAmountValue = this.quote.balanceToBill - forecastedAmount
            this.calculateAmount()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeDialog')
        },
        calculateForecastedAmount() {
            let currentDate = new Date()
            currentDate = `${currentDate.getFullYear()}-${String(
                currentDate.getMonth() + 1
            ).padStart(2, '0')}`

            this.isCurrentMonth =
                this.entry.date && this.entry.date == currentDate

            let total = 0
            const forecastIndex = this.forecast.findIndex(
                forecast => forecast.id == this.quote.id
            )
            if (forecastIndex > -1) {
                const forecast = this.forecast[forecastIndex].forecast
                if (forecast) {
                    total = forecast.reduce(
                        (accumulator, entry) =>
                            accumulator +
                            (entry.amount &&
                            moment(entry.date).isSameOrAfter(currentDate)
                                ? Number(entry.amount)
                                : 0),
                        0
                    )
                    const currentEntry = forecast.find(
                        e => e.date == this.entry.date
                    )
                    if (currentEntry) {
                        total -= Number(currentEntry.amount)
                    }
                }
            }
            return total
        },
        addEntry() {
            if (
                this.createDialog ||
                (this.originalEntry.amount == 0 &&
                    this.originalEntry.percentage == 0)
            ) {
                this.entry.initialForecast = this.checkbox
                this.$emit('addEntry', this.entry)
            } else {
                this.entry.initialForecast = this.checkbox
                this.$emit('replaceEntry', this.entry)
            }
            this.closeDialog()
            this.checkbox = false
        },
        calculateAmount() {
            this.entry.amount = (
                (Number(this.entry.percentage || 0) / 100) *
                this.quote.balanceToBill
            ).toFixed(2)
        },
        calculatePercentage() {
            this.entry.percentage = (
                (Number(this.entry.amount || 0) * 100) /
                this.quote.balanceToBill
            ).toFixed(3)
        },
        maxAmount() {
            if (Math.abs(this.entry.amount) > Math.abs(this.maxAmountValue)) {
                return `The value cannot be greater than ${this.maxAmountValue}.`
            } else {
                return true
            }
        },
        allowedDates(val) {
            return !this.dates.includes(val)
        },
        isFirstFiveDaysOfMonth() {
            const day = moment().date()
            return day <= 5
        },
    },
}
</script>
